* {
    border: none;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

header {
    background-color: #FFFFFF;
    border-bottom: 1px solid #dbdbdb;
    padding: 15px;
    /* position: fixed; */
    /* top: 0; */
    width: 100%;
}

header ul {
    display: flex;
    justify-content: space-around;
}

header li {
    list-style-type: none; 
}

header a {
    color: black;
    font-weight: normal;
    text-decoration: none;
    transition: all .2s ease-in;
}

header a:hover{
    border-bottom: 1px solid #dbdbdb;
    transition: all .2s ease-in;
}

footer {
    background-color: white;
    text-align: center;
    font-size: 1rem;
    font-weight: lighter;
    padding: 50px;
}

footer a {
    color: black;
}



.credit {
    background-color: #FFFFFF;
    font-size: .75rem;
    font-weight: lighter;
    text-align: right;
    padding: 15px;
}

.piece {
    background-color: white;
    min-height: 900px;
    padding: 25px 50px;
}

.piece-container {
    max-width: 1200px;
    margin: 0 auto;
}

.intro-container {
    max-width: 1100px;
    margin: 0 auto;
}

.title {
    font-size: 6rem;
    font-weight: bold;
}

.logo {
    display: block;
    margin: 25px auto 0 auto;
    width: 65%;
}

.subtitle {
    font-size: 2.75rem;
    font-weight: normal;
    margin: 0 auto 50px auto;
    text-align: center;
    width: 80%;
}

.copy {
    font-size: 1.5rem;
    font-weight: lighter;
    line-height: 1.6;
    margin: 25px 9px;
    max-width: 800px;
}

.title-copy {
    font-size: .9rem;
    text-align: center;
    margin: 0px auto 0 auto;
}

.stat-copy {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: lighter;
    line-height: 1.6;
    max-width: 800px;
    margin-top: -15px;
    margin-left: 15px;
}

.finding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 150px auto 200px auto;
    text-align: center;
    width: 75%;
}

.interview__finding {
    margin: 150px 0 200px 0;
}

.in__copy {
    margin: 0 0 0 8px;
}

.finding-svg {
    height: 100px;
    width: 250px;
    margin: 25px auto;
}

.recommendation-svg {
    width: 75%;
    margin: 25px auto;
}

.finding svg {
    height: 100%;
    width: 100%;
}

.finding__title {
    font-size: 3rem;
}

.finding__copy {
    font-size: 1.5rem;
    font-weight: lighter;
    line-height: 1.6;
    margin: 0 auto;
    max-width: 700px;
    text-align: center;
}

.biglink {
    color: black;
    font-size: 1.5rem;
    font-weight: lighter;
    display: block;
    margin: 25px 0 25px 8px;
}

.full-width-img {
    top: 0;
    position: sticky;
    width: 100%;
    z-index: -1;
}

.bigtext {
    font-size: 5rem;
    text-align: center;
    margin: 0px 0 0 0;
}

.svg {
    height: 75px;
    width: 120px;
    margin: 0px auto;
}

.svg svg {
    height: 100%;
    width: 100%;
}

.copy--center {
    text-align: center;
    margin: 0 auto;
}

.tidbit {
    margin: 75px auto;
}

.counter {
    font-size: 8rem;
    font-weight: normal;
}

.bar {
    border: 2px solid #000000;
    border-radius: 15px;
    height: 25px;
    width: 100%;
}

.bar-fill {
    background-color: #000000;
    border: 2px solid #FFFFFF;
    border-radius: 15px;
    height: 100%;
    position: relative;
    width: 0%;
    z-index: -1;
}

.bar-fill--1 {
    animation: fill1 3s ease-out forwards;
}
.bar-fill--2 {
    animation: fill2 3s ease-out forwards;
}
.bar-fill--3{
    animation: fill3 3s ease-out forwards;
}
.bar-fill--4{
    animation: fill4 3s ease-out forwards;
}
.bar-fill--5 {
    animation: fill5 3s ease-out forwards;
}

@keyframes fill1 {
    from { width: 0%; }
    to { width: 65%;}
}

@keyframes fill2 {
    from { width: 0%; }
    to { width: 80%;}
}
@keyframes fill3 {
    from { width: 0%; }
    to { width: 43%;}
}
@keyframes fill4 {
    from { width: 0%; }
    to { width: 74%;}
}
@keyframes fill5 {
    from { width: 0%; }
    to { width: 62%;}
}

.visual {
    display: inline-block;
}

.stat-piece {
    margin: 150px 0;
}

.persons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.persons svg {
    height: 100%;
    min-width: 10%;
    margin: 10px 10px;
    transition: all .2s ease-in;
    max-height: 300px;
}

.persons svg:hover {
    transform: scale(1.03);
    transition: all .2s ease-in;
}

.people-figures-1 {
    margin: 50px auto;
    width: 100%;
}

.people-figures-2 {
    width: 100%;
    margin: 50px auto;
}

.people-figures-3 {
    width: 100%;
    margin: 50px auto;
}

.people-figures-2 svg {
    width: 15%;
}

.people-figure-text {
    text-align: center;
    margin: 0 auto;
}

.big-number {
    font-size: 8rem;
}

.process {
    margin: 150px auto 200px auto;
}

.process__title {
    font-size: 4.5rem;
    margin: 0 0 0 8px;
}

.process__img {
    box-shadow: 0px -3px 12px -9px #dbdbdb;
    display: block;
    margin: 50px auto;
    width: 80%;
}

.process a {
    color: black;
}

.process__words {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 150px auto 200px auto;
}

.process__word {
    font-size: 2rem;
    font-weight: lighter;
    margin: 15px 0;
    text-align: center;
    padding: 15px;
    width: 33%;
}

.recommendation__title {
    font-size: 4.5rem;
}

@media screen and (max-width: 800px) {

    header ul {
        flex-wrap: wrap;
    }

    header li {
        margin: 5px 0;
    }

    header a {
        font-size: .85rem;
    }

    .logo {
        width: 100%;
    }

    .title {
        font-size: 1.7rem;
        word-wrap: break-word;
    }

    .recommendation__title {
        font-size: 1.7rem;
        word-wrap: break-word;
    }

    .subtitle {
        font-size: 1.5rem;
        margin: 25px auto;
        width: 100%;
    }

    .piece {
        padding: 25px 15px;
    }

    .finding__title {
        font-size: 1.5rem;
    }

    .finding-svg {
        height: 150px;
        margin: 0 auto;
        width: 75px;
    }

    .copy {
        font-size: 1rem;
        margin: 25px 2px;
    }

    .title-copy {
        font-size: .75rem;
    }

    .people-figures-1 {
        margin: 50px auto 0 auto;
    }

    .people-figures-1 svg {
        width: 10%;
    }

    .counter {
        font-size: 5rem;
    }

    .stat-copy {
        font-size: 1rem;
        margin: 0 auto 25px auto;
        margin-left: 0;
    }

    .big-number {
        font-size: 4rem;
    }

    .process__title {
        font-size: 1.5rem;
    }

    .process__img {
        width: 100%;
    }

    .process__word {
        font-size: 1rem;
        font-weight: normal;
        width: 50%;
        margin: 0;
    }

    .finding__copy {
        font-size: 1rem;
    }

    .piece {
        min-height: 450px;
    }

    .bigtext {
        font-size: 4rem;
    }

    .process__words {
        margin: 50px auto;
    }

    footer p {
        font-size: .75rem;
        margin: 15px auto;
    }

    .biglink {
        font-size: 1rem;
        margin: 25px 0 25px 2px;
    }

    .full-width-img {
        object-fit: cover;
        object-position: center;
        height: 120vh;
    }

}
